import React, { useState, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';
import { email } from '@config';
import { navDelay, loaderDelay } from '@utils';
import Inner from '../../images/circle_inner.png'
import Outer from '../../images/circle_outer.png'

const StyledHeroSection = styled.section`
  ${({ theme }) => theme.mixins.flexCenter};
  flex-direction: column;
  align-items: flex-start;
  min-height: 100vh;
  padding: 0;

  @media (max-width: 480px) and (min-height: 700px) {
    padding-bottom: 10vh;
  }

  h3 {
    margin: 0 0  10px 4px;
    color: var(--green);
    font-family: var(--font-mono);
    font-size: clamp(var(--fz-sm), 5vw, var(--fz-md));
    font-weight: 400;

    @media (max-width: 480px) {
      margin: 0 0 20px 2px;
    }
  }

  h1, h2 {
    margin: 0;
    font-size: clamp(30px, 8vw, 60px);
    text-transform: uppercase;
    margin-bottom: 10px;
    line-height: 0.9;
  }
  h2 {
    font-size: clamp(20px, 6vw, 40px);
  }

  p {
    margin: 20px 0 0;
    max-width: 500px;
  }

  .email-link {
    ${({ theme }) => theme.mixins.bigButton};
    margin-top: 50px;
  }
  .m-intro{
    position: absolute;
    width: 70%;
    height: 100vh;
    top: 0;
    right: 0;
    filter: hue-rotate(205deg);
    z-index: -1;
    @media (max-width: 768px) {
      display: none;
    }

    &:before, &:after{
      display:block;
      content:" ";
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position:absolute;
      transform-origin:center;
      background-position:center;
      background-repeat:no-repeat;
      z-index:50;
    }
    
    &:before{
      background-image:url(${Inner});
      background-size:contain;
      animation: big-spin 16s linear infinite;
    }
    
    &:after{
      background-image:url(${Outer});
      background-size:contain;
      animation: big-spin 16s linear infinite;
  
    }
  }
  

  @keyframes big-spin {
    0% {
       transform: rotate(0deg);
    }
    100% {
       transform: rotate(360deg);
    }
   }

  .line {
      position: relative;
      width: 80px;
      height: 10px;
      display: block;
      margin: 20px 0;
      transform: translate(-25%) scale(0.5);

      &:before, &:after{
        content:'';
        position: absolute;
        height: 10px;
        width: 200%;
      }
      &:before {
        background: linear-gradient(45deg, transparent, transparent 49%, var(--line) 49%, transparent 51%);
      }
      &:after {
          background: linear-gradient(-45deg, transparent, transparent 49%, var(--line) 49%, transparent 51%);
      }
      &:before, &:after{
        background-size: 20px 20px;
      }
    }
`;

const Hero = () => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(true), navDelay);
    return () => clearTimeout(timeout);
  }, []);

  const one = <span className="line"></span>;
  const two = <h1>Luke Balson.</h1>;
  const three = <h2>Design Technologist</h2>;
  const six = <div className='m-intro'></div>;
  const four = (
    <p>I am a senior design technologist with a strong background in UX design, UI development and design systems. I'm passionate about improving experiences through user centered design and problem solving. </p>  );
    const five = (
      <a href={`mailto:${email}`} className="email-link">
        Get In Touch
      </a>
    );

  const items = [one, two, three, four, one];

  return (
    <StyledHeroSection>
      <div className='m-intro'></div>
      <TransitionGroup component={null}>
        {isMounted &&
          items.map((item, i) => (
            <CSSTransition key={i} classNames="fadeup" timeout={loaderDelay}>
              <div style={{ transitionDelay: `${i + 1}00ms` }}>{item}</div>
            </CSSTransition>
          ))}
      </TransitionGroup>
    </StyledHeroSection>
  );
};

export default Hero;
